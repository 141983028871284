@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  font-family: "Quicksand", sans-serif;
}

/* Fullscreen container */
.home {
  width: 100%;
  height: 89.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* About section */
.about {
  margin-top: -500px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background: none;
  position: relative;
  z-index: 2;
}

/* Typing Effect */
.typing {
  font-size: 8rem; /* Default for large screens */
  font-weight: 300;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid #ffffff; /* Blinking cursor effect */
  animation: blinkCursor 0.8s infinite;
}

/* Blinking cursor animation */
@keyframes blinkCursor {
  50% {
    border-color: transparent;
  }
}

/* Subtext */
.prompt h3 {
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
  color: #f7f4f4;
}

/* Social Media Icons */
.socialMedia {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.socialMedia a {
  color: #ffffff;
  font-size: 40px;
  transition: transform 0.3s ease;
}

.socialMedia a:hover {
  transform: scale(1.2);
}

/* ✅ Responsive Typography */

/* Tablets */
@media (max-width: 1024px) {
  .typing {
    font-size: 5rem; /* Adjust title for tablets */
  }
}

/* Small Tablets & Large Phones */
@media (max-width: 768px) {
  .typing {
    font-size: 3.5rem; /* Reduce further for smaller devices */
  }

  .prompt h3 {
    font-size: 16px; /* Reduce subtitle text size */
  }
}

/* Mobile Phones */
@media (max-width: 480px) {
  .typing {
    font-size: 2.5rem; /* Further reduce title size */
  }

  .prompt h3 {
    font-size: 14px;
  }
}
