@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hide the form when message is sent */
.hidden {
  display: none;
}

/* Message sent confirmation */
.sent-message {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
  animation: fadeInUp 0.8s ease-out;
}

.contact {
  color: #ffffff;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Quicksand", sans-serif;
  padding: 40px 20px;
  box-sizing: border-box;
}

.contact h2 {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  animation: fadeInUp 0.6s ease-out;
  margin-top: -20px;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: 400px;
  animation: fadeInUp 0.8s ease-out;
}

/* Inputs and textarea */
.contact input,
.contact textarea {
  margin-bottom: 20px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  color: black;
  transition: all 0.3s ease-in-out;
  font-family: "Quicksand", sans-serif;
}

.contact input:focus,
.contact textarea:focus {
  background-color: #3a3a3a;
  outline: none;
  box-shadow: 0 0 10px rgba(100, 100, 255, 0.5);
}

/* Button */
.contact button {
  padding: 15px;
  background-color: #3e497a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}

.contact button:hover {
  background-color: #2c3559;
  transform: scale(1.05);
}

.contact button:active {
  transform: scale(0.95);
}

/* ✅ Mobile-friendly adjustments */
@media (max-width: 600px) {
  .contact form {
    width: 90%;
  }

  .contact input,
  .contact textarea {
    padding: 12px; /* Slightly smaller padding */
    font-size: 14px; /* Smaller text */
  }

  .contact button {
    padding: 12px;
    font-size: 16px;
  }
}
