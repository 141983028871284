@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* Main Projects Section */
.projects {
  overflow-x: hidden; /* This prevents horizontal scrolling */
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 20px;
  position: relative;
  text-align: center;
  box-sizing: border-box; /* Prevent width calculations from breaking */
}


/* Dark Overlay for Projects Page */
.projects::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: -1;
}

/* Title Styling */
.projectTitle {
  text-align: center;
  color: white;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: bold;
  animation: fadeInUp 0.6s ease-out;
  margin-top: -10px;
}

/* Project List Layout */
.projectList {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  animation: fadeInUp 0.8s ease-out;
}

/* Project Item Card */
.projectItem {
  width: 320px; /* Default width */
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(141, 141, 141); 
  padding: 25px;
  border-radius: 14px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.18);
  animation: fadeInUp 0.6s ease-out;
  text-align: center;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect */
.projectItem:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 20px rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

/* Project Image */
.projectItem .bgImage {
  width: 100%;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

/* Project Title */
.projectItem h3 {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

/* Project Skills */
.projectItem .skills {
  font-size: 14px;
  color: #d1d1d1;
  font-weight: 500;
  margin-top: 5px;
}

/* GitHub Link Button */
.projectItem .projectLink {
  display: inline-block;
  margin-top: 12px;
  padding: 8px 14px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.projectItem .projectLink:hover {
  background-color: rgba(255, 255, 255, 0.35);
}

/* ✅ Mobile & Tablet Fixes */
@media only screen and (max-width: 800px) {
  .projectList {
    flex-direction: column;
    align-items: center;
  }

  .projectItem {
    width: 80%; /* Items use 80% of the screen width */
    max-width: 500px; /* Optional: prevents items from being too wide */
  }
}

/* Even smaller screens (phones) */
@media only screen and (max-width: 480px) {
  .projects {
    padding: 20px 10px;
  }

  .projectTitle {
    font-size: 22px; /* Slightly smaller title */
  }

  .projectItem {
    width: 80%; /* Use almost full width for smaller phones */
  }
}
