@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  animation: fadeInUp 0.6s ease-out;
}

.experience-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 40px;
  padding: 20px 0;
  border: none;
  min-height: 78vh;
}

/* Each Experience Item */
.experience-item {
  flex: 0 0 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  animation: fadeInUp 0.6s ease-out;
  text-align: center;
  color: white;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.experience-item:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2);
}

/* Item Content */
.experience-item-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Year/Tag */
.experience-tag {
  display: inline-block;
  color: white;
  padding: 5px 12px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}

.experience-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.experience-item p {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 10px;
  max-width: 90%;
}

/* ✅ Mobile Layout: Stack Items Vertically */
@media (max-width: 768px) {
  .experience-container {
    flex-direction: column;
    align-items: center; /* Center the column-stacked items */
    overflow-x: hidden; /* No horizontal scrolling needed */
    gap: 20px;
  }

  .experience-item {
    width: 80%; /* Take almost full width on small screens */
    max-width: 400px; /* Optional: prevent overly large cards */
    max-height: 150px;
  }
}
