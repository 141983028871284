.App {
  text-align: center;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body{
  padding: 0;
  margin: 0;
}




