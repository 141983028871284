@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .skillsSection {
    overflow-x: hidden;
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 20px;
    position: relative;
    text-align: center;
    box-sizing: border-box;
  }
  
  .skillsSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); 
    z-index: -1;
  }
  
  .skillsTitle {
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 25px;
    animation: fadeInUp 0.6s ease-out;
    margin-top: -10px;
  }
  
  .skillsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    animation: fadeInUp 0.8s ease-out;
    width: 100%;
  }
  
  .skillsCard {
    background-color: rgb(141, 141, 141);
    padding: 20px;
    width: 320px;
    max-width: 320px;
    border-radius: 14px;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.18);
    color: white;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skillsCard:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(255, 255, 255, 0.25);
  }
  
  .skillsCategory {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .skillsList {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .skillItem {
    font-size: 16px;
    color: #d1d1d1;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .skillIcon {
    font-size: 20px;
    color: #ffffff;
  }
  
  /* Mobile Friendly */
  @media only screen and (max-width: 800px) {
    .skillsContainer {
      flex-direction: column;
      align-items: center;
    }
  
    .skillsCard {
      width: 80%;
      max-width: 500px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .skillsSection {
      padding: 20px 10px;
    }
  
    .skillsTitle {
      font-size: 22px;
    }
  
    .skillsCard {
      width: 90%;
    }
  }
  